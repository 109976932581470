<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <table class="default-table">
          <tbody>
            <tr>
              <td>Frage</td>
              <td>
                <DataPatcher
                  :itemValue="data.question"
                  itemName="question"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="input"
                  inputType="text"
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>Detailierte Beschreibung</td>
              <td>
                <DataPatcher
                  :itemValue="data.infotext"
                  itemName="infotext"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="textarea-md"
                  inputType=""
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>Frage-Typ</td>
              <td>
                <DataPatcher
                  :itemValue="data.type"
                  itemName="type"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="select"
                  :selectOptions="typeItems"
                  inputType=""
                  itemValueKey="value"
                  itemTextKey="name"
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col
        v-if="
          data &&
          data.type !== 'text' &&
          data.type !== 'longtext' &&
          data.type !== 'date'
        "
        cols="12"
      >
        <v-card tile flat>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              Antworten
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <table class="drag-table">
              <draggable
                v-model="data.answers"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="handleDragEnd"
                :handle="disabledHandleByPermission(permissions.write)"
              >
                <tr
                  v-for="answer in data.answers"
                  :key="answer.id"
                  class="is-draggable"
                >
                  <td class="draghandle" style="width: 32px">
                    <v-icon v-if="permissions.write"> mdi-drag </v-icon>
                    <v-icon v-else disabled style="opacity: 0.5"
                      >mdi-drag
                    </v-icon>
                  </td>

                  <td>{{ answer.answer }}</td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="green"
                      elevation="0"
                      @click="openAnswer(answer)"
                      class="mx-1"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="red"
                      elevation="0"
                      @click="askDelete(answer)"
                      class="mx-1"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="creatingActive"
              :disabled="disabledByPermission(permissions.write)"
              color="green"
              class="white--text"
              @click="addItem()"
            >
              {{ $t("plugins.documentMaker.newAnswer") }}
              <v-icon right dark>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <template v-else>
        <v-col v-if="data">
          <v-card tile flat>
            <v-app-bar flat color="primary">
              <v-toolbar-title class="title white--text pl-0">
                Antworten
              </v-toolbar-title>
            </v-app-bar>

            <v-card-text>
              Dieser Frage-Typ ({{ data.type }}) erfordert keine festgelegten
              Antworten.
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-dialog
      v-model="answerDialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="answerDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title> Antwort </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <AnswerDetails
            :data="answerData"
            :permissions="permissions"
            :key="componentKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Diese Antwort löschen?"
      text="Möchtest Du diese Antwort tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import draggable from "vuedraggable";
import DataPatcher from "@/components/ui/DataPatcher";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import AnswerDetails from "@/components/plugins/document-maker/AnswerDetails";
export default {
  name: "QuestionDetails",

  components: {
    draggable,
    DataPatcher,
    AnswerDetails,
    AreYouSureDialog,
  },

  data() {
    return {
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      creatingActive: false,
      answerDialog: false,
      answerData: [],
      componentKey: 0,
      itemToDelete: null,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/plugins/docmaker/${this.data.docUuid}/set/${this.data.setId}/question/${this.data.uuid}`,
      typeItems: [
        {
          name: "Texteingabe",
          value: "text",
        },
        {
          name: "Datum",
          value: "date",
        },
        {
          name: "Auswahl",
          value: "select",
        },
        {
          name: "Checkboxen",
          value: "checkbox",
        },
        {
          name: "Radio",
          value: "radio",
        },
        {
          name: "Langer Text (Textarea)",
          value: "longtext",
        },
      ],
    };
  },

  props: ["data", "permissions"],

  created: function () {
    //
  },

  methods: {
    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.deleteItem();
      }
    },

    async deleteItem() {
      console.log(this.itemToDelete);

      const url = `/company/${this.companyID}/plugins/docmaker/${this.data.docUuid}/set/${this.data.setUuid}/question/${this.data.uuid}/answer/${this.itemToDelete.uuid}`;
      let response = await this.delete(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.data.answers.forEach((el, index) => {
          if (el.uuid === this.itemToDelete.uuid) {
            indexToDelete = index;
          }
        });

        this.data.answers.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    openAnswer(answer) {
      this.answerData = answer;
      this.componentKey += 1;
      this.answerDialog = true;
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    disabledHandleByPermission(value) {
      // .draghandle

      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async dragUpdate(data) {
      console.log(data);

      const url = `/company/${this.companyID}/plugins/docmaker/${this.data.docUuid}/set/${this.data.setUuid}/question/${this.data.uuid}/answers/prio`;

      let response = await this.put(url, JSON.stringify(data), "json"); // await data from mixin

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async addItem() {
      let response = await this.post(
        `company/${this.companyID}/plugins/docmaker/${this.data.docUuid}/set/${this.data.setUuid}/question/${this.data.uuid}/answer`
      ); // await data from mixin

      let newItem = response.data.data.result[0];
      this.data.answers.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Die Antwort wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    handleDragEnd: function (e) {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      let idAndPrio = [];
      const newOrderedArray = this.data.answers;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>
