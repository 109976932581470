<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <table class="default-table">
          <tbody>
            <tr>
              <td>Antwort</td>
              <td>
                <DataPatcher
                  :itemValue="data.answer"
                  itemName="answer"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="input"
                  inputType="text"
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>Wert</td>
              <td>
                <DataPatcher
                  :itemValue="data.value"
                  itemName="value"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="input"
                  inputType="text"
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>Feedback/Ergebnis</td>
              <td>
                <DataPatcher
                  :itemValue="nullNotAllowed(data.feedback)"
                  itemName="feedback"
                  :route="patchRoute"
                  :permissionsWrite="permissions.write"
                  inputElement="textarea-md"
                  inputType=""
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import DataPatcher from "@/components/ui/DataPatcher";
export default {
  name: "AnswerDetails",

  components: {
    DataPatcher,
  },

  data() {
    return {
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      creatingActive: false,
      answerDialog: false,
      answerData: [],
      componentKey: 0,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/plugins/docmaker/${this.data.docUuid}/set/${this.data.setUuid}/question/${this.data.questionUuid}/answer/${this.data.uuid}`,
      typeItems: [
        {
          name: "Texteingabe",
          value: "text",
        },
        {
          name: "Datum",
          value: "date",
        },
        {
          name: "Auswahl",
          value: "select",
        },
        {
          name: "Checkboxen",
          value: "checkbox",
        },
        {
          name: "Radio",
          value: "radio",
        },
        {
          name: "Langer Text (Textarea)",
          value: "longtext",
        },
      ],
    };
  },

  props: ["data", "permissions"],

  created: function () {
    //
  },

  methods: {
    nullNotAllowed(value) {
      if (value === null || value === "Null") {
        return "";
      } else {
        return value;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>
