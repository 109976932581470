<template>
  <v-container>
    <PageHeader :pageTitle="pageTitle" :pageDescription="pageDescription" />
    <v-row v-if="data">
      <v-col cols="12">
        <table class="default-table">
          <tbody>
            <tr>
              <td>{{ $t("phrases.Title") }}</td>
              <td>
                <DataPatcher
                  :itemValue="doc.name"
                  itemName="name"
                  :route="patchRoute"
                  :permissionsWrite="writePerm"
                  inputElement="input"
                  inputType="text"
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>{{ $t("plugins.documentMaker.Documentinfo") }}</td>
              <td>
                <DataPatcher
                  :itemValue="doc.infoText ? doc.infoText : ''"
                  itemName="infoText"
                  :route="patchRoute"
                  :permissionsWrite="writePerm"
                  inputElement="textarea-md"
                  inputType=""
                  @emit="mutateData"
                ></DataPatcher>
              </td>
            </tr>

            <tr>
              <td>{{ $t("phrases.Preview") }}</td>
              <td>
                <v-btn
                  tile
                  color="success"
                  :href="`https://friedacomp.studios-in-motion.com/docmaker/preview.html?uuid=${doc.uuid}&cid=${companyID}`"
                  target="_blank"
                >
                  {{ $t("phrases.Preview") }}
                  <v-icon right> mdi-open-in-new </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              Sets
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <table class="drag-table" v-if="data">
              <draggable
                v-model="doc.sets"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="handleDragEnd"
                :handle="disabledHandleByPermission(writePerm)"
              >
                <tr v-for="set in doc.sets" :key="set.id" class="is-draggable">
                  <td class="draghandle" style="width: 32px">
                    <v-icon v-if="writePerm"> mdi-drag </v-icon>
                    <v-icon v-else disabled style="opacity: 0.5"
                      >mdi-drag
                    </v-icon>
                  </td>

                  <td>
                    {{ set.name }} (#{{ set.id }})

                    <v-chip
                      class="ma-2"
                      small
                      color="primary"
                      v-if="set.ifQuestionId"
                    >
                      <v-icon left small> mdi-information-outline </v-icon>
                      Conditional
                    </v-chip>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      x-small
                      color="green"
                      elevation="0"
                      class="white--text"
                      @click="openSet(set)"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </td>

                  <td style="width: 32px">
                    <v-btn
                      fab
                      x-small
                      color="red"
                      elevation="0"
                      class="white--text"
                      @click="askDelete(set)"
                      :disabled="disabledByPermission(deletePerm)"
                    >
                      <v-icon dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </table>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              :loading="creatingActive"
              :disabled="disabledByPermission(writePerm)"
              color="green"
              class="ma-2 white--text"
              @click="addItem()"
            >
              {{ $t("plugins.documentMaker.newSet") }}
              <v-icon right dark>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card tile>
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0">
              {{ $t("plugins.documentMaker.Document") }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <DataPatcher
              :itemValue="doc.document ? doc.document : 'Kein Inhalt'"
              itemName="document"
              :route="patchRoute"
              :permissionsWrite="writePerm"
              inputElement="textarea-md"
              inputType=""
              @emit="mutateData"
            ></DataPatcher>

            <PlaceholderInfo :documentUuid="this.$route.params.id" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="setDialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="setDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title> Set </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <SetDetails
            :data="setData"
            :permissions="auth.permissions"
            :key="componentKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Dieses Set löschen?"
      text="Möchtest Du dieses Set tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import SetDetails from "@/components/plugins/document-maker/SetDetails";
import draggable from "vuedraggable";
import DataPatcher from "@/components/ui/DataPatcher";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import PlaceholderInfo from "@/components/plugins/document-maker/PlaceholderInfo";

export default {
  name: "DocumentMakerDetail",

  components: {
    PageHeader,
    draggable,
    SetDetails,
    DataPatcher,
    AreYouSureDialog,
    PlaceholderInfo,
  },

  data() {
    return {
      safeguardingDialog: false,
      test: "",
      dataUuid: this.$route.params.id,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: this.$t("plugins.documentMaker.name"),
      pageDescription: "",
      fetchinData: true, // should be true when fetching data
      creatingActive: false,
      auth: [],
      data: [],
      sets: [],
      doc: [],
      docName: "",
      document: "",
      setData: [], // gets filled @ click
      setDialog: false,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      dragging: false,
      futureItem: null,
      movingItem: null,
      futureIndex: null,
      movingIndex: null,
      targetIndex: null,
      sourceIndex: null,
      targetId: null,
      sourceId: null,
      componentKey: 0,
      itemToDelete: null,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/plugins/docmaker/${this.$route.params.id}`,
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    async deleteItem() {
      const url = `/company/${this.companyID}/plugins/docmaker/${this.dataUuid}/set/${this.itemToDelete.uuid}`;
      let response = await this.delete(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.doc.sets.forEach((el, index) => {
          if (el.uuid === this.itemToDelete.uuid) {
            indexToDelete = index;
          }
        });

        this.doc.sets.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    openSet(set) {
      this.setData = set;
      this.componentKey += 1;
      this.setDialog = true;
    },

    disabledHandleByPermission(value) {
      // .draghandle

      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async dragUpdate(data) {
      const url = `/company/${this.companyID}/plugins/docmaker/${this.dataUuid}/sets/prio`;

      let response = await this.put(url, JSON.stringify(data), "json"); // await data from mixin

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/plugins/docmaker/${this.dataUuid}`
      ); // await data from mixin

      this.data = response.data.data;
      this.doc = response.data.data.doc[0];
      this.auth = response.data.auth;
      this.writePerm = this.auth.permissions.write;
      this.deletePerm = this.auth.permissions.delete;
      this.readPerm = this.auth.permissions.read;
      this.masterPerm = this.auth.permissions.master;
      this.fetchinData = false;
    },

    emittedTableEvent(obj) {
      console.log(obj);
      if (obj.action === "editItem") {
        this.$router.push({
          name: "plugins.documentmaker.:id",
          params: { id: obj.itemId },
        });
      } else if (obj.action === "deleteItem") {
        alert("DELETE");
      }
    },

    async addItem() {
      let response = await this.post(
        `company/${this.companyID}/plugins/docmaker/${this.dataUuid}/set`
      );

      if (response.status === 200) {
        let newItem = response.data.data.result[0];
        newItem.questions = []; // adding an empty answers array to avoid refetching
        this.doc.sets.push(newItem);

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Ein Set wurde erfolgreich angelegt.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    handleDragEnd: function (e) {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      console.log(this.doc.sets);

      let idAndPrio = [];
      const newOrderedArray = this.doc.sets;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },

    mutateData(value) {
      console.log(value.name);
      console.log(value.value);
      this.doc[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}

.jmp-table .type-blank {
  background-color: white;
}

.jmp-table .type-regular {
  background-color: white;
}

.jmp-table .type-video {
  background-color: #222222 !important;
  color: white;
  min-height: 5px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
