<template>
  <div>
    <v-container v-if="data.length > 0">
      <h2>{{ $t("phrases.Placeholder") }}</h2>
      <table class="default-table caption">
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              {{ item.question }}
              <span v-if="item.type === 'text' || item.type === 'long-text'"
                >(Nutzer-Eingabe)</span
              >
            </td>
            <td>%%{{ item.feedbackPlaceholder }}%%</td>
          </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "PlaceholderInfo",

  components: {},

  data() {
    return {
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: true, // should be true when fetching data
      data: [],
    };
  },

  props: ["documentUuid"],

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/plugins/docmaker/${this.documentUuid}/questions`
      );

      this.data = response.data.data;
      this.fetchinData = false;
    },

    emittedTableEvent(obj) {
      console.log(obj);
      if (obj.action === "editItem") {
        this.$router.push({
          name: "plugins.documentmaker.:id",
          params: { id: obj.itemId },
        });
      } else if (obj.action === "deleteItem") {
        alert("DELETE");
      }
    },

    async addItem() {
      let response = await this.post(
        `company/${this.companyID}/plugins/docmaker/${this.dataUuid}/set`
      ); // await data from mixin

      let newItem = response.data.data.result[0];

      newItem.questions = []; // adding an empty answers array to avoid refetching

      this.sets.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Das Dokument wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    handleDragEnd: function (e) {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      let idAndPrio = [];
      const newOrderedArray = this.sets;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },

    mutateData(value) {
      console.log(value.name);
      console.log(value.value);
      this.doc[value.name] = value.value; // mutate
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}

.jmp-table .type-blank {
  background-color: white;
}

.jmp-table .type-regular {
  background-color: white;
}

.jmp-table .type-video {
  background-color: #222222 !important;
  color: white;
  min-height: 5px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
